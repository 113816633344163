/*
 * API 统一管理。使用时按需导入。
 * 团队开发可以创建多个模块分别引入axioms index.us. 管理自己的接口。
 * 使用：
 * 组件内，导入api.js 
 * import {apiAddress} from “./api.js”
 * 
 */
import qs from 'qs'; // qs模块，用来序列化post类型的数据
import { get, post, request, download } from './http'

// let year = new Date().getFullYear();

// export const apiAddress = p => post('api/v1/users', p);

//新闻模块请求
export const article = {
    //无参数           
    getNewsData() {
        return get('api/get_news_data');
    },
    // 带参数    
    articleDetail(id, params) {
        return get('api/v1/users', {
            params: params
        });
    },

}

//登录模块请求
export const getLogin = {
    // post提交    
    login(params) {
        return post('api/login', params);
    },
    //注册接口
    Registered(params) {
        return post('api/register/add', params);
    },
    //忘记密码接口
    Psword(params) {
        return post('api/password/reset', params);
    },

    // 其他接口………

    // 新闻详情
    getNewsDetails(params) {
        return post('api/get_news_details', params);
    },
    // 修改密码
    getNewsPassword(params) {
        return post('api/password/update', params);
    },
    // 动态文件获取
    getFile(params) {
        return post('api/get_official_content_data', params);
    },

    // 创新赛队长身份校验
    verify(params) {
        return post('api/innovate/identity/verify', params);
    },
    // 创新赛队长时间校验
    verifyTime(params) {
        return post('api/innovate/time/verify', params);
    },
    // 创新赛文件获取下载的url
    verifyFileUrl(params) {
        return post('api/innovate/file/url', params);
    },



    //上传文件信息
    record(params) {
        return post('api/file/info/record', params);
    },
    //上传文件记录
    Document(params) {
        return post('api/file/info/select', params);
    },
    //获取用户名密码
    secret_key(params) {
        return post('api/member/promoted/secret_key', params);
    }
}

//其它模块


export const userInfo = {
    //已审核用户
    selsetUser(params) {
        return post('api/sign_up/select', params);
    },
    //未审核用户
    notSelsetUser(params) {
        return post('api/payment/select', params);
    },
    //删除
    deleteUser(params) {
        return post('api/user/delete', params);
    },
    //用户保存
    saveUser(params) {
        return post('api/user/save', params);
    },
    //用户信息导入
    importUinfo(params) {
        return post('api/sign_up/add', params);
    },
    //用户信息导出
    exportUinfo(params) {
        return post('api/user/info/export', params);
    },
    //用户信息导入
    erroDownload(params) {
        return get('api/data/download', params);
    },
    //发票
    invoice(params) {
        return post('api/payment/add', params);
    },
    //获奖名单数据
    winnerList(params) {
        return post('api/winner_list/select', params);
    },
    //修改指导教师名字
    editInfo(params) {
        return post('api/teacher/info/record', params);
    },

    //获取菜单权限
    menu_power(params) {
        return post('api/login_sign', params);
    },

    //视频信息记录
    video_record(params) {
        return post('api/video/info/record', params);
    },

    //视频信息记录查询
    video_select(params) {
        return post('api/video/info/select', params);
    },

    //邮寄地址保存
    mailing_address(params) {
        return post('api/mail/address/save', params);
    },

    //邮寄地址查询
    find_address(params) {
        return post('api/mail/address/select', params);
    },

    //单价获取
    price() {
        return get('api/payment/unit/price/get');
    },
    //
    certificate(params) {
        return post('api/certificate/info/select', params);
    },
    paymentInfo(params) {
        return post('api/payment/company/info/select', params);
    },
    
    //ict查询
    ictDataSelect(params) {
        return post('api/sign_up/select', params);
    },
    //成员信息修改
    infoUpdate(params) {
        return post('api/members/info/update', params);
    },
		// 缴费验证
    payVerify(params) {
			return post('api/payment/verify', params);
		},
		// 缴费创建
    payCreate(params) {
			return post('api/payment/create', params);
		},
		// 未缴费信息删除
    payDelete(params) {
			return post('api/payment/wait_pay/delete', params);
		},
		// 缴费证明提交
    paySubmit(params) {
			return post('api/payment/certify/submit', params);
		},
		//获取缴费信息
		getPayInfo(params) {
			return post('api/payment/select', params);
		},
}

//报名首页接口
export const getHome = {
    getSelect(params) {
        return post('api/sign_up/info/select', params);
    },
}

//发票开具模块请求
export const invoiceInfo = {
    //获取表格数据
    invoiceData(params) {
        return post('api/invoice/info/select', params);
    },
    //开票信息保存
    invoiceSave(params) {
        return post('api/invoice/info/save', params);
    },
    //开票信息提交
    invoiceConfirm(params) {
        return post('api/invoice/info/confirm', params);
    },
		// 查询缴费开票
		payInvoice(params) {
			return post('api/payment/invoice/info/select',params);
		},
		// 查询开票信息
		getInvoiceInfo(params) {
			return post('api/invoice/latest/select',params);
		},
		// 下载发票地址
		downloadInvoice(params) {
			return post('api/invoice/url/get',params);
		},
		// 重开发票
		reInvoice(params) {
			return post('api/invoice/file/reissue',params);
		},
		// 确认开票
		ConfirmInvoice(params) {
			return post('api/invoice/info/save',params);
		},
}
//移动端预约
export const moveAppointment = {
    //发送验证码
    sendVerification(params) {
        return post('api/send/verification_code', params);
    },
    //对比验证码
    contrastVerification(params) {
        return post('api/compare/verification_code', params);
    },
    //考试赛道查询
    info(params) {
        return post('api/exam/sort/info', params);
    },
    //考试信息查询
    select(params) {
        return post('api/exam_info/select', params);
    },
    //时间地点查询
    place_time(params) {
        return post('api/competition/place_time/info', params);
    },
    //预约信息保存
    save(params) {
        return post('api/appointment_info/save', params);
    },
    //获取二维码
    grcode(params) {
        return post('api/qrcode/info', params);
    },

}

// 报名系统二维码展示查询
export const qrcodeInfo = {
  //获取二维码
	grcode(params) {
		return get(`pay/qrCode?paymentId=${params.paymentId}&money=${params.money}&userId=${params.user_id}&paymentCompany=${params.paymentCompany}`, {});
	},
	//获取支付状态
	getPayState(params) {
		return get(`pay/queryOrder?paymentId=${params.paymentId}&cmbOrderId=${params.cmbOrderId}`, {});
	},
    //关闭订单
	closeOrder(params) {
		return get(`pay/closeOrder?cmbOrderId=${params.cmbOrderId}&paymentCompany=${params.paymentCompany}`, {});
	},
}